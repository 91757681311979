import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import CONSTANTS from '../../Constants';
import { useCustomNavigation } from '../../Hooks';
import { garmentService } from '../../Services';
import { GarmentDTO } from '../../Services/garmentService';

import GarmentCard from '../../Components/GarmentCard';
import SkeletonCard from '../../Components/SkeletonCard';

import styles from './index.module.scss';
import BreadCrumb from '../../Components/BreadCrumbs';

const HomePage = () => {
    const nav = useCustomNavigation();
    const { category, subcategory } = useParams();

    const fetchGarments = () => {
        if (subcategory && category) {
            return garmentService.fetchGarmentsSubcategories(category, subcategory);
        } else if (category) {
            return garmentService.fetchGarmentsByCategory(category);
        } else {
            return garmentService.fetchAllGarments();
        }
    };

    const { isPending, isError, data, error } = useQuery({
        queryKey: ['garments', category, subcategory],
        queryFn: fetchGarments,
    });

    if (isPending) {
        return (
            <div className={`${styles.home} container`}>
                {Array.from({ length: 15 }).map((_, index) => (
                    <SkeletonCard key={index} />
                ))}
            </div>
        );
    }

    if (isError) {
        return (
            <div className="container">
                <span>Error: {error.message}</span>
            </div>
        );
    }
    return (
        <div className="container">
            <BreadCrumb />
            <div className={styles.home}>
                {data?.garments.map((garment, index) => (
                    <GarmentCard
                        key={garment.id}
                        id={garment.id}
                        title={garment.title}
                        subcategory={garment?.sub_categories?.[0]?.name || ''}
                        firstImageUrl={garment?.images?.[0]?.url || ''}
                        secondImageUrl={garment?.images?.[1]?.url || ''}
                        price={garment.variants[0].price}
                        style={{ animationDelay: `${index * 150}ms` }} // Staggered delay
                    />
                ))}
            </div>
        </div>
    );
};

export default HomePage;
