import { User } from '../Stores/UserStore';
import api from '../Utils/api';

export type GetUserResponse = User;

export const userService = {
    getUser: async (): Promise<User> => {
        return api.get(`/v2/user`);
    },
};
