import { action, makeAutoObservable, observable } from 'mobx';
import CONSTANTS from '../Constants';

export interface State {
    onboarded?: string;
    phase_one?: string;
    phase_two?: string;
    agreed_to_terms?: string;
    images_submitted?: string;
    approved_for_testing?: string;
}

export interface User {
    id: string;
    partner_id: string;
    created: string;
    created_by: string;
    updated: string;
    updated_by: string;
    first_name: string;
    last_name: string;
    email: string;
    company: string;
    company_role: string;
    approved: string;
    confirmed: string;
    approved_by: string;
    company_url?: string;
    partner_state: State;
}

const defaultUser: User = {
    id: '',
    created: '',
    created_by: '',
    updated: '',
    updated_by: '',
    first_name: '',
    partner_id: '',
    last_name: '',
    email: '',
    company: '',
    company_role: '',
    approved: '',
    confirmed: '',
    approved_by: '',
    company_url: '',
    partner_state: {},
};

class UserStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable
    public user: User = defaultUser;

    @action
    public setUser(user: Partial<User>): void {
        this.user = { ...this.user, ...user };
    }

    @action
    public getUser(): User {
        return this.user;
    }

    @action
    public clearUser(): void {
        this.user = defaultUser;
    }

    @action
    public isLogin(): boolean {
        return Boolean(localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    }
}

export default UserStore;
