import CONSTANTS from '../Constants';
import React from 'react';
import useStores from './use-stores';

export interface IUseCustomPath {
    where: string;
    id?: string;
    params?: string;
}

export const useCustomPath = ({ where, id, params }: IUseCustomPath) => {
    const { languageStore } = useStores();

    const customPath = React.useMemo(() => {
        const language = (languageStore.currentLanguage as any) || CONSTANTS.SupportedLanguage.EN;
        const routesInLanguage = (CONSTANTS.ROUTES as any)?.[language]?.[where];

        if (!routesInLanguage) {
            console.error(`Route ${where} not found in language ${language}`);
            return '';
        }

        if (id) {
            const routesInLanguageWithoutLast3Chars = routesInLanguage.slice(0, -3);
            return routesInLanguageWithoutLast3Chars + id;
        }

        return `${routesInLanguage}${params ? params : ''}`;
    }, [languageStore.currentLanguage, where, id, params]);

    return customPath;
};
