import React from 'react';
import { inject } from 'aurelia-dependency-injection';
import { LanguageStore, LoadingStore, AuthStore, UserStore } from '../Stores';

export interface GlobalStores {
    languageStore: LanguageStore;
    loadingStore: LoadingStore;
    authStore: AuthStore;
    userStore: UserStore;
}

@inject(LanguageStore, LoadingStore, AuthStore, UserStore)
export class StoresContext {
    context: React.Context<GlobalStores>;

    constructor(
        languageStore: LanguageStore,
        loadingStore: LoadingStore,
        authStore: AuthStore,
        userStore: UserStore
    ) {
        this.context = React.createContext({
            languageStore,
            loadingStore,
            authStore,
            userStore,
        });
    }
}
