import { observer } from 'mobx-react-lite';
import { Route, Routes } from 'react-router-dom';

import CONSTANTS from '../Constants';
import { ROUTE, ROUTER_CONFIG } from './config';
import { useStores } from '../Hooks';
import useAuthRedirect from '../Hooks/use-auth-redirect';
import { useEnsureRightLanguage } from '../Hooks/use-ensure-right-language';
import { DefaultLayout } from '../Layout';
import { useLogin } from '../Hooks/use-login';

const Router = observer(() => {
    const { languageStore } = useStores();
    useAuthRedirect();
    useEnsureRightLanguage();
    const { isLoggingIn } = useLogin();

    if (isLoggingIn) {
        return null;
    }

    return (
        <Routes>
            {Object.keys(CONSTANTS.ROUTES).map((language) => {
                return ROUTER_CONFIG.map((route: ROUTE) => {
                    const path = (CONSTANTS.ROUTES as any)[language][route.path];

                    return (
                        <Route
                            key={path || route.path}
                            path={path || route.path}
                            Component={() => {
                                const Content = route.children;

                                if (route.noLayout) {
                                    return <Content />;
                                }

                                return (
                                    <DefaultLayout
                                        options={
                                            (route.seo as any)?.[
                                                languageStore.currentLanguage?.toLocaleLowerCase() ||
                                                    'en'
                                            ]
                                        }
                                    >
                                        <Content />
                                    </DefaultLayout>
                                );
                            }}
                        />
                    );
                });
            })}
        </Routes>
    );
});

export default Router;
