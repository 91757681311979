import { CSSProperties, FC } from 'react';
import ProteaIcon from '../../Icons/protea';
import styles from './index.module.scss';
import { useCustomPath } from '../../Hooks/use-custom-path';
import CONSTANTS from '../../Constants';
import { Link } from 'react-router-dom';

interface GarmentCardProps {
    id: string;
    firstImageUrl: string;
    secondImageUrl: string;
    title: string;
    subcategory: string;
    price: number;
    style?: CSSProperties;
}
const GarmentCard: FC<GarmentCardProps> = ({
    id,
    firstImageUrl,
    secondImageUrl,
    subcategory,
    title,
    style,
    price,
}) => {
    const garmentDetailPath = useCustomPath({
        where: CONSTANTS.SCREEN.PRODUCT_DETAIL,
        id,
    });

    return (
        <div className={styles.card} style={style}>
            <ProteaIcon className={styles.icon} />
            <Link to={garmentDetailPath}>
                <div className={styles.imageContainer}>
                    <img src={firstImageUrl} className={styles.firstImage} alt="First" />
                    <img src={secondImageUrl} className={styles.secondImage} alt="Second" />
                </div>
            </Link>
            <div className={styles.info}>
                <div>
                    <Link to={garmentDetailPath}>
                        <h3 className={styles.info__title}>{title}</h3>
                    </Link>
                    <p className={styles.info__subcategory}>{subcategory}</p>
                </div>
                <p className={styles.info__price}>{price}$</p>
            </div>
        </div>
    );
};

export default GarmentCard;
