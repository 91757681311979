import './index.scss';
import Button from '../Button';
import CONSTANTS from '../../Constants';
import CategoryDrawer from './CategoryDrawer';
import Copydeck from '../../i18n/Copydeck';
import UserAccountIcon from '../../Icons/user-account';
import { useCustomNavigation } from '../../Hooks';
import { useState } from 'react';
import { useStores } from '../../Hooks';
import { getUrlForEnvironment } from '../../Utils/environment';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
    const customNavigate = useCustomNavigation();
    const { authStore } = useStores();

    const getManageAccountURL = () => getUrlForEnvironment(CONSTANTS.MANAGE_ACCOUNT_URL);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const navigateToCategory = (categoryID: string) => {
        customNavigate(CONSTANTS.SCREEN.CATEGORY, { category: categoryID });
    };

    const toggleUserMenu = () => {
        setIsUserMenuOpen((prev) => !prev);
    };

    const handleLogout = () => {
        try {
            authStore.clearAuthInfo();
            localStorage.clear();
            customNavigate(CONSTANTS.SCREEN.LOGIN);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <nav className="Navbar container">
            <div className="Navbar__content">
                <div className={`BurgerMenu ${isMenuOpen ? '-active' : ''}`} onClick={toggleMenu}>
                    <span className="BurgerMenu__top"> </span>
                    <span className="BurgerMenu__middle"></span>
                    <span className="BurgerMenu__bottom"></span>
                </div>
                <div className="Navbar__buttonGroups">
                    <Button
                        type="text"
                        className="pl-0"
                        onClick={() => navigateToCategory(CONSTANTS.CATEGORY_ID.WOMEN)}
                    >
                        {Copydeck.navbarNavbarCategoryWomen}
                    </Button>
                    <Button
                        type="text"
                        onClick={() => navigateToCategory(CONSTANTS.CATEGORY_ID.MEN)}
                    >
                        {Copydeck.navbarNavbarCategoryMen}
                    </Button>
                </div>
                <div className="Navbar__title-container">
                    <span
                        onClick={() => customNavigate(CONSTANTS.SCREEN.HOME)}
                        className="Navbar__title"
                    >
                        {Copydeck.protea}
                    </span>
                </div>
                <div className="Navbar__buttonGroups -right">
                    <Button type="text" onClick={toggleUserMenu}>
                        <UserAccountIcon fill="black" />
                    </Button>
                    {isUserMenuOpen && (
                        <div className="Navbar__userMenu">
                            <button
                                type="button"
                                onClick={() => window.open(getManageAccountURL(), '_blank')}
                            >
                                {Copydeck.navbarNavbarManageAccount}
                            </button>
                            <button type="button" onClick={handleLogout}>
                                {Copydeck.navbarNavbarLogout}
                            </button>
                        </div>
                    )}
                    {/* TODO: Reactivate this when multiple languages*/}
                    {/* <select
                        name="lang-selector"
                        id="lang-selector"
                        value={languageStore.currentLanguage || CONSTANTS.SupportedLanguage.EN}
                        onChange={(e) =>
                            handleLanguageChange(e.target.value as CONSTANTS.SupportedLanguage)
                        }
                    >
                        {Object.values(CONSTANTS.SupportedLanguage).map((lang) => (
                            <option value={lang} key={lang}>
                                {lang}
                            </option>
                        ))}
                    </select> */}
                </div>
            </div>
            <CategoryDrawer open={isMenuOpen} />
        </nav>
    );
};

export default Navbar;
