import { action, autorun, makeAutoObservable } from 'mobx';
import CONSTANTS from '../Constants';

interface AuthInfo {
    access_token: string;
    refresh_token: string;
    unixTimestamp_expires_in: number;
    user_id: string;
}

const defaultUser: AuthInfo = {
    access_token: '',
    refresh_token: '',
    unixTimestamp_expires_in: 0,
    user_id: '',
};

class AuthStore {
    authInfo: AuthInfo = defaultUser;

    constructor() {
        makeAutoObservable(this);

        autorun(() => {
            if (this.authInfo.access_token) {
                localStorage.setItem(
                    CONSTANTS.LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
                    this.authInfo.access_token
                );
                localStorage.setItem(
                    CONSTANTS.LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
                    this.authInfo.refresh_token
                );
                localStorage.setItem(
                    CONSTANTS.LOCAL_STORAGE_KEYS.UNIXTIMESTAMP_EXPIRES_IN,
                    (
                        Math.floor(Date.now() / 1000) + this.authInfo.unixTimestamp_expires_in
                    ).toString()
                );
            }
        });
    }

    @action
    setAuthInfo(user: Partial<AuthInfo>) {
        this.authInfo = { ...this.authInfo, ...user };
    }

    @action
    clearAuthInfo() {
        this.authInfo = defaultUser;
    }

    get hasValidToken(): boolean {
        return Boolean(this.authInfo.access_token);
    }
}

export default AuthStore;
