import { useState, useCallback, useEffect } from 'react';
import CONSTANTS from '../Constants';
import { userService } from '../Services/userService';
import useCustomNavigation from './use-custom-navigation';
import useStores from './use-stores';
import { authService } from '../Services';

export const useLogin = () => {
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const { userStore, loadingStore } = useStores();
    const customNavigate = useCustomNavigation();

    const loginIfTokenExists = useCallback(async () => {
        if (userStore.isLogin() && userStore.user.email === '') {
            try {
                setIsLoggingIn(true);
                loadingStore.addLoading();
                const user = await userService.getUser();
                userStore.setUser(user);
                customNavigate(CONSTANTS.SCREEN.HOME);
            } catch (err) {
                try {
                    const response = await authService.refresh(
                        localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || ''
                    );

                    localStorage.setItem(
                        CONSTANTS.LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
                        response.access_token
                    );
                    localStorage.setItem(
                        CONSTANTS.LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
                        response.refresh_token
                    );
                    const user = await userService.getUser();
                    userStore.setUser(user);
                    return;
                } catch (err) {
                    localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
                    localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
                    userStore.clearUser();
                    customNavigate(CONSTANTS.SCREEN.LOGIN);
                }

                localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
                localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
                userStore.clearUser();
                customNavigate(CONSTANTS.SCREEN.LOGIN);
            }

            loadingStore.removeLoading();
            setIsLoggingIn(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loginIfTokenExists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isLoggingIn };
};
