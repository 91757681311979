import CONSTANTS from '../Constants';
import { AuthDTO } from '../Services/authService';
import { getUrlForEnvironment } from './environment';

const getApiBase = (): string => getUrlForEnvironment(CONSTANTS.API_URL);

const defaultHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
};

interface ApiError {
    source: string;
    message: string;
}

const handleResponse = async <T>(response: Response) => {
    if (!response.ok) {
        const error = await response.json();
        console.log(error);
        const errorMessages = error.errors?.map((err: ApiError) => err.message) || [
            'An error occurred',
        ];
        throw new Error(JSON.stringify(errorMessages));
    }
    return response.json() as Promise<T>;
};

const refreshAccessToken = async (refreshToken: string): Promise<AuthDTO> => {
    const response: AuthDTO = await api.post(`/v1/auth/refresh`, { refresh_token: refreshToken });
    return response;
};

let refreshTokenPromise: Promise<AuthDTO> | null = null;

const refreshAccessTokenIfNeeded = async (): Promise<AuthDTO | undefined> => {
    const expirationTimeStamp = localStorage.getItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.UNIXTIMESTAMP_EXPIRES_IN
    );
    const refreshToken = localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    const tokenExpiration = !!expirationTimeStamp ? parseInt(expirationTimeStamp) : '';
    // No token currently set.
    if (!refreshToken || !tokenExpiration) {
        return undefined;
    }
    if (Math.round(Date.now() / 1000) > tokenExpiration) {
        if (!refreshTokenPromise) {
            refreshTokenPromise = refreshAccessToken(refreshToken);
        }
        try {
            const authResponse = await refreshTokenPromise;
            refreshTokenPromise = null;

            localStorage.setItem(
                CONSTANTS.LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
                authResponse.access_token
            );
            localStorage.setItem(
                CONSTANTS.LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
                authResponse.refresh_token
            );
            localStorage.setItem(
                CONSTANTS.LOCAL_STORAGE_KEYS.UNIXTIMESTAMP_EXPIRES_IN,
                (Math.floor(Date.now() / 1000) + authResponse.expires_in).toString()
            );

            return authResponse;
        } catch (e) {
            localStorage.clear();
            window.location.href = '/auth';
        }
    }
};

const api = {
    get: async <T>(endpoint: string, token?: string): Promise<T> => {
        const headers = { ...defaultHeaders };
        const accessToken = localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

        if (accessToken) {
            const newToken: AuthDTO | undefined = await refreshAccessTokenIfNeeded();
            headers['X-Cognito-Auth'] = `${newToken?.access_token ?? accessToken}`;
        }
        const response = await fetch(`${getApiBase()}${endpoint}`, {
            method: 'GET',
            headers,
        });
        return handleResponse<T>(response);
    },
    post: async <T>(endpoint: string, body: any): Promise<T> => {
        const response = await fetch(`${getApiBase()}${endpoint}`, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(body),
        });
        return handleResponse<T>(response);
    },
};

export default api;
