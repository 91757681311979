import api from '../Utils/api';

export interface AuthDTO {
    request_id: string;
    access_token: string;
    refresh_token: string;
    expires_in: string;
    user_id: string;
}

const authService = {
    login: async (email: string, password: string): Promise<AuthDTO> => {
        return api.post(`/v1/auth/login`, { email, password });
    },
    refresh: async (refreshToken: string): Promise<AuthDTO> => {
        return api.post('/v1/auth/refresh', { refresh_token: refreshToken });
    },
};

export default authService;
