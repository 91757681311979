import { useEffect } from 'react';
import { getKeyByValueRecursive } from '../Utils/object';
import useCustomNavigation from './use-custom-navigation';
import CONSTANTS from '../Constants';
import useStores from './use-stores';

export const useEnsureRightLanguage = () => {
    const customNavigate = useCustomNavigation();
    const { languageStore } = useStores();

    const EnsureRightLanguage = () => {
        try {
            const path = window.location.pathname;
            const keyRoutesOfPath = getKeyByValueRecursive(CONSTANTS.ROUTES, path);

            if (
                keyRoutesOfPath?.split('.')?.[0]?.includes((languageStore as any).currentLanguage)
            ) {
                // User is in the right language
                return;
            }

            if (!keyRoutesOfPath) {
                throw new Error('No key found for this path');
            }

            const lastKey = keyRoutesOfPath.split('.').pop();
            if (!lastKey) {
                throw new Error('Internal error');
            }

            const newPath = (CONSTANTS.ROUTES as any)[(languageStore as any).currentLanguage][
                lastKey
            ];
            if (!newPath) {
                throw new Error('Any path found');
            }

            customNavigate(newPath);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (window.location.pathname && languageStore.currentLanguage != null) {
            EnsureRightLanguage();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname, languageStore.currentLanguage]);
};
