import './index.scss';
import { FC, useEffect } from 'react';
import { useCustomNavigation, useStores } from '../../../Hooks';
import CONSTANTS from '../../../Constants';
import Copydeck from '../../../i18n/Copydeck';
import { getUrlForEnvironment } from '../../../Utils/environment';
import { useTranslation } from 'react-i18next';

const getManageAccountURL = () => getUrlForEnvironment(CONSTANTS.MANAGE_ACCOUNT_URL);

interface CategoryDrawerProps {
    open: boolean;
}

const MENU_ENTRIES = [
    {
        title: 'navbar.navbar_category_women',
        categoryId: CONSTANTS.CATEGORY_ID.WOMEN,
        enable: true,
    },
    {
        title: 'navbar.navbar_category_men',
        categoryId: CONSTANTS.CATEGORY_ID.MEN,
        enable: true,
    },
];

const CategoryDrawer: FC<CategoryDrawerProps> = ({ open }) => {
    const customNavigate = useCustomNavigation();
    const { authStore } = useStores();
    const { t } = useTranslation();

    const navigateToCategory = (categoryId: string) => {
        customNavigate(CONSTANTS.SCREEN.CATEGORY, { category: categoryId });
    };

    const handleLogout = () => {
        try {
            authStore.clearAuthInfo();
            localStorage.clear();
            customNavigate(CONSTANTS.SCREEN.LOGIN);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [open]);

    if (!open) {
        return null;
    }

    return (
        <div className={`CategoryDrawer CategoryDrawer${open ? '' : '--close'}`}>
            <div className="CategoryDrawer__overlay">
                <div className="CategoryDrawer__content">
                    <ul className="CategoryDrawer__menu">
                        {MENU_ENTRIES.map((item, index) => (
                            <li key={index} className="CategoryDrawer__menuItem">
                                <span
                                    onClick={() => navigateToCategory(item.categoryId)}
                                    className={`CategoryDrawer__menuLink${
                                        !item.enable ? '--disable' : ''
                                    }`}
                                >
                                    {t(item.title)}
                                </span>
                            </li>
                        ))}
                    </ul>
                    <ul className="CategoryDrawer__menu--account">
                        <li className="CategoryDrawer__menuItem">
                            <span
                                onClick={() => window.open(getManageAccountURL(), '_blank')}
                                className="CategoryDrawer__menuLink"
                            >
                                {Copydeck.navbarNavbarManageAccount}
                            </span>
                        </li>
                        <li className="CategoryDrawer__menuItem">
                            <span onClick={handleLogout} className="CategoryDrawer__menuLink">
                                {Copydeck.navbarNavbarLogout}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CategoryDrawer;
