import React, { useEffect, useRef, useState } from 'react';
import { ImageDTO } from '../../Services/garmentService';
import Slider, { Settings } from 'react-slick';
import './index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import lottieJson from '../../Assets/Img/tryOn-lottie.json';
import { AnimationItem, AnimationDirection } from 'lottie-web';

interface ProductCarouselProps {
    images: ImageDTO[];
    onClickTryOn: () => void;
}

const ProductCarousel: React.FC<ProductCarouselProps> = ({ images, onClickTryOn }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const slideRef = useRef<Slider>(null);
    const [isMobile, setIsMobile] = useState(true);
    const { t } = useTranslation();
    const lottieRef = useRef<AnimationItem>(null);

    const BREAKPOINT_MD = 768;

    const SETTINGS_MAIN: Settings = {
        arrows: false,
        dots: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        fade: false,
        vertical: false,
        className: 'mainSlider',
    };

    const SETTINGS_MOBILE: Settings = {
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        fade: false,
        className: 'mobileSlider',
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= BREAKPOINT_MD);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial state

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (slideRef.current) {
            slideRef.current.slickGoTo(currentImageIndex);
        }
    }, [currentImageIndex]);

    const onAnimationComplete = () => {
        if (lottieRef.current) {
            lottieRef.current.setDirection(
                (lottieRef.current.playDirection * -1) as AnimationDirection
            );
            lottieRef.current.play();
        }
    };

    return (
        <div className="imageGallery">
            <div className="thumbnails">
                {images.map((image: ImageDTO, index: number) => (
                    <img
                        key={image.url}
                        className="thumb"
                        src={image.url}
                        alt={t('thumbnail')}
                        onClick={() => {
                            setCurrentImageIndex(index);
                        }}
                    />
                ))}
            </div>
            <Slider {...(isMobile ? SETTINGS_MOBILE : SETTINGS_MAIN)} ref={slideRef}>
                {images.map((image: ImageDTO) => (
                    <div key={image.url}>
                        <img className="image" src={image.url} alt={t('product')} />
                    </div>
                ))}
            </Slider>
            <button onClick={onClickTryOn} className="tryOn" aria-labelledby="try on garment">
                <span style={{ visibility: 'hidden' }}>try on garment</span>
                <Lottie
                    play
                    loop={false}
                    onComplete={onAnimationComplete}
                    animationData={lottieJson}
                    ref={lottieRef}
                />
            </button>
        </div>
    );
};

export default ProductCarousel;
